import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { IFeedback } from "../Interfaces/IFeedback";
import { IBook } from "../Interfaces/IBook";
import { checkExistingFeedback, givenFeedbackIsDown, givenFeedbackIsUp } from "../lib/Functions";

interface CustomBook {
    book: IBook;
    givenFeedback: IFeedback[];
    feedbackSetter: any;
    showModal: Function;
}

const FaqFeedback = ({ book, givenFeedback, feedbackSetter, showModal }: CustomBook): JSX.Element => {

    const { setJobIDFeedback, setFeedback, setAlreadyDone, setRank } = feedbackSetter;
    
    const selectedFeedback: IFeedback | false = checkExistingFeedback(book.aipenJobsInfo.idFaq, givenFeedback);

    const onClickFaqFeedback = (value: boolean) => {
        setJobIDFeedback(book.aipenJobsInfo.idFaq);
        setFeedback(true);
        setAlreadyDone(selectedFeedback);
        setRank(selectedFeedback ? selectedFeedback.vote : value);
        showModal(false);
    }

    return (
        <div className="flex gap-2">
            <FontAwesomeIcon icon={faThumbsUp} className={`cursor-pointer h-6 mt-1 ${givenFeedbackIsUp(selectedFeedback)}`} onClick={e => onClickFaqFeedback(true) }/>
            <FontAwesomeIcon icon={faThumbsDown} className={`cursor-pointer h-6 mt-2 ${givenFeedbackIsDown(selectedFeedback)}`} onClick={e => onClickFaqFeedback(false) }/>
        </div>
    );
};

export default FaqFeedback;
