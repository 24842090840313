import { useEffect, useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Banner, Grid, H5, Tag } from "@maggioli-design-system/react";
import * as AttachService from "../services/Attachments";
import * as BookService from "../services/Books";
import Attachments from "../components/Attachments";
import ILibro from "../components/ILibro";
import { ILibroCard, FilterYear } from "../components/Sidebar";
import { scrollTop, replaceMenuName, serverResponseOK, replaceAreaName } from "../lib/Functions";
import { IBook } from "../Interfaces/IBook";
import { IYear } from "../Interfaces/IYear";
import { ILoader } from "../Interfaces/ILoader";
import { IProdotto } from "../Interfaces/IProdotto";
import RenderBooks from "../components/RenderBooks";
import { IRedaHandler } from "../Interfaces/IRedaHandler";
import { MIN_LENGTH_SEARCH } from "../lib/Constants";

export interface IHasAttach {
    isbn: string;
    has_contents: boolean;
}

interface ICategory {
    setShowModal: Function;
    searchText: string;
    loader: ILoader;
    redazionale: IRedaHandler;
    idProdotti?: IProdotto[];
}

export interface IModal {
    visible: boolean;
    book: IBook;
}

const Category = ({ setShowModal, loader, idProdotti, redazionale }: ICategory): JSX.Element => {

    const { slug, page, id }: any = useParams();
    const [searchParams] = useSearchParams();
    const history = useNavigate();

    const [modal, setModal] = useState<IModal>({ visible: false, book: null });
    const [show, setShow] = useState<boolean>(false);
    const [isbn, setIsbn] = useState<string>("");
    const [viewer, setViewer] = useState<string>("");
    const [books, setBooks] = useState<IBook[]>([]);
    const [checkAttach, setCheckAttach] = useState<IHasAttach[]>([]);
    const [years, setYears] = useState<string[]>([]);
    const [selectedYear, setSelectedYear] = useState<string>(searchParams.get("anno") === null ? "Tutti" : searchParams.get("anno"));
    const [endResult, setEndResult] = useState<boolean>(false);
    const [firstLoad, setFirstLoad] = useState<boolean>(true);

    const CATEGORY_NAME: string = replaceMenuName(slug.replaceAll("-", " "));
    const AREE_NAME: string = replaceAreaName(slug.replaceAll("-", " "));

    useEffect(() => {

        getYears();

        function handleEnterPress(key: string) {
            const searchbar: any = window.document.getElementById("searchbar");
            if (key !== "Enter" || searchbar === null) {
                return;
            }
            if (searchbar.value.length >= MIN_LENGTH_SEARCH) {
                history(`/cerca/1/${searchbar.value}`);
            } 
        }
        window.addEventListener("keypress", e => handleEnterPress(e.key));

        return () =>  {
            window.removeEventListener("keypress", e => handleEnterPress(e.key));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        updateTitle();

        return () => updateTitle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [CATEGORY_NAME]);

    useEffect(() => {
        // history(`/categoria/${slug}/${id}/1`);
        updateYearInUrl(selectedYear);
        getBooks();
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedYear]);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            return;
        }
        getBooks();
        scrollTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false);
            return;
        }
        getBooks(page - 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    const scrollDown = () => {
        const y = sessionStorage.getItem("scrollPage");
        if (y !== null && parseInt(y) > 0) {
            window.scrollTo({
                top: parseInt(y),
                behavior: "smooth",
            });
            sessionStorage.removeItem("scrollPage");
        }
    };

    const updateYearInUrl = (year: string) => {
        if(year === "Tutti") {
            history(`/categoria/${slug}/${id}/1`);
        } else {
            history(`/categoria/${slug}/${id}/1?anno=${year}`);
        }
    }

    // Aggiorna il titolo della pagina
    const updateTitle = () => {
        document.title = `${CATEGORY_NAME} | Biblioteca digitale Maggioli`;
    };

    // Salva i libri nella variabile e setta gli altri parametri
    const saveBooks = (data: IBook[], page: number) => {
        if (page === 0 || (!endResult && data.length > 0)) {
            setBooks((oldArray) => (page > 0 ? [...oldArray, ...data] : data));
            setEndResult(false);
        }
        if (data.length < 10) {
            setEndResult(true);
        }

        scrollDown();
    };

    // Controlla se ci sono contenuti aggiuntivi su Approfondimenti per i volumi passati
    const checkAttachments = (data: IBook[], page: number) => {
        AttachService.checkAttachments(data.map((elem) => elem.isbn).toString())
            .then((result) => {
                if (serverResponseOK(result)) {
                    setCheckAttach((oldArray) => (page > 0 ? [...oldArray, ...result.data.data] : result.data.data));
                }
            })
            .catch((e) => console.log(e));
    };

    // Restituisce gli anni di pubblicazione dei libri dal Redazionale
    const getYears = () => {
        BookService.getRedaBookYears().then((result) => {
            if (serverResponseOK(result)) {
                setYears(result.data.map((elem: IYear) => elem.key));
            }
        });
    };

    // Prende i libri dal redazionale, a seconda della pagina, area e anno
    const getBooks = (newPage = 0) => {
        const size: number = books.length === 0 && page > 1 ? page * 10 : 10;
        loader.setLoader(true);
        BookService.getRedaBooks(newPage, selectedYear, AREE_NAME, size).then((result) => {
            loader.setLoader(false);
            if (serverResponseOK(result)) {
                result.data.length > 0 && checkAttachments(result.data, newPage);
                saveBooks(result.data, newPage);
            }
        }).catch((e) => console.log(e));
    };

    // Titolo del volume da mostrare nella modale dell'iLibro
    const bookTitle = (): string => {
        const book: IBook = books.filter((elem) => elem.isbn === isbn)[0];
        return book === undefined ? "" : book.formati !== undefined && book.formati.includes("iLibro") ? `iLibro - ${book.name}` : book.name;
    };

    return (
        <div className="px-8 background-color-adjust-tone border-b-2 border-adjust-tone-18">
            <div className="view-limit pt-8 pb-0 lg:pb-8">
                <Grid template="category" gutter="xlarge">
                    <div className="hidden lg:block">
                        <Grid className="sticky top-32">
                            {/* filtro per anni */}
                            {<FilterYear data={years} selectYear={(v) => setSelectedYear(v)} defaultValue={selectedYear}/>}
                            
                            {/* Sidebar */}
                            <ILibroCard/>
                        </Grid>
                    </div>
                    <Grid rows="fit-vertically">
                        {/* Banner errore no libri da mostrare */}
                        {books.length === 0 && !loader.visible && (
                            <Grid rows="fit-vertically">
                                <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail">
                                    Non ci sono volumi relativi all'anno e la categoria selezionati.
                                </Banner>
                            </Grid>
                        )}
                        <Grid columns="2" gutter="xlarge" rows="fit-vertically">
                            {/* Lista libri */}
                            { <RenderBooks
                                    data={books}
                                    setShow={setShow}
                                    setShowModal={setShowModal}
                                    setViewer={setViewer}
                                    setIsbn={setIsbn}
                                    hasAttach={checkAttach.map((elem) => elem.has_contents)}
                                    setAttachModal={setModal}
                                    idProdotti={idProdotti}
                                /> }
                        </Grid>
                    </Grid>
                </Grid>
                <div className="text-center -mx-58 lg:mx-0 py-0 pt-8 lg:py-24">
                    {!endResult ? (
                        // bottone per caricare più risultati
                        <Tag chip className="cursor-pointer background-color-adjust-tone-18 hover:background-color-adjust-tone-15"
                            onClick={() => history(`/categoria/${slug}/${id}/${parseInt(page) + 1}`)}>
                            <H5>Carica più risultati...</H5>
                        </Tag>
                    ) : (
                        // bottone per caricare più risultati
                        <Tag chip className="cursor-pointer background-color-adjust-tone-18 hover:background-color-adjust-tone-15" onClick={() => scrollTop()}>
                            <H5>Torna su</H5>
                        </Tag>
                    )}
                </div>

                {/* Sidebar */}
                <ILibroCard className="md:grid lg:hidden my-8" />

                {/* Modale iLibro */}
                <ILibro show={show} setShow={setShow} isbn={isbn} title={bookTitle()} cache={Date.now()} viewer={viewer} />

                {/* Modale allegati */}
                <Attachments redazionale={redazionale} loader={loader} key={modal.book ? modal.book.isbn : 0} book={modal.book}
                    visible={modal.visible} hideModal={() => setModal((p) => ({ ...p, visible: false }))}
                />
            </div>
        </div>
    );
};

export default Category;
