import { Banner, Button, H3, Modal, Detail, Row, Caption, Icon, Grid } from "@maggioli-design-system/react";
import * as Constants from "../lib/Constants";
import { Accordion, AccordionItem } from "./Accordion";
import { IFeedback } from "../Interfaces/IFeedback";
import ReactMarkdown from "react-markdown"
import AIGenerated from "./AIGenerated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { askQuestionJob, checkUser, getQAInBook } from "../services/AIpen";
import { IBook } from "../Interfaces/IBook";
import { IQuestion } from "../Interfaces/AipenJobs";
import { checkExistingFeedback, checkFeedback, givenFeedbackIsDown, givenFeedbackIsUp } from "../lib/Functions";

interface IQAs {
    book: IBook,
    placeholder?: string,
    setShowModal: Function,
    givenFeedback: IFeedback[],
    updateFeedback: Function,
    feedbackSetter: any
}

const QAs = ({ book, placeholder = "", setShowModal, givenFeedback, updateFeedback, feedbackSetter }: IQAs): JSX.Element => {
    
    const userIsLogged: boolean = Constants.getCookie("refresh_token") !== null;
    const aipenID: number = book.aipenDocumentiId;

    const [copy, setCopy] = useState<boolean>(false);
    const [showQAinfo, setShowQAinfo] = useState<boolean>(false);
    const [infoText, setInfoText] = useState<boolean>(false);
    const [generating, setGenerating] = useState<boolean>(false);
    const [newQuestion, setNewQuestion] = useState<string>("");
    const [questions, setQuestions] = useState<IQuestion[]>([]);

    useEffect(() => {
        if(book.aipenInitStatus !== "COMPLETED" || !userIsLogged) {
            return;
        }
        getQAInBook(aipenID).then(res => {
            if (res.status !== 200 || !Array.isArray(res.data)) {
                return;
            }
            setQuestions(res.data);
        })
    }, [])

    const onClickCopyAnswerText = (e, text: string) => {
        e.stopPropagation();
        navigator.clipboard.writeText(text);
        setCopy(true);
        setTimeout(() => setCopy(false), 2000);
    }

    const onClickGenerateAnswer = async () => {

        if(!userIsLogged || !book.hasAccessToExtraContent) {
            setShowModal(true);
            return;
        }

        if(newQuestion.length < 10) {
            alert("La domanda deve essere lunga almeno 10 caratteri.");
            return;
        }

        const userChecked = localStorage.getItem("aipenuser") !== null;
        if(!userChecked) {
            const checkUserRes = await checkUser();
            if (checkUserRes.status) {
                localStorage.setItem("aipenuser", "true");
            }
        }

        const payload = {
            task: "q&a",
            args: {
                language: "it",
                question: newQuestion,
            },
        };
        setGenerating(true);
        askQuestionJob(payload, aipenID).then(res => {
            if (!res.data.status) {
                setGenerating(false);
                alert("Errore nella generazione della risposta");
                return;
            }
            setTimeout(() => {
                getQAInBook(aipenID).then(res2 => {
                    setGenerating(false);
                    if (res2.status !== 200 || !Array.isArray(res2.data)) {
                        return;
                    }
                    setQuestions(res2.data);
                    setInfoText(questions.length === res2.data.length);  
                })
            }, 5000);

        })
    }
    
    return <Grid rows="fit-vertically gap-10">
        { aipenID !== 0 && 
            <div className="rounded-xl grid gap-5 -mb-5">
                <Grid className={`rounded-2xl transition-all gap-1 p-0 bg-transparent`}>

                    <H3 className="items-center inline-flex gap-2">Inserisci la tua domanda<AIGenerated/></H3>
                    
                    { Constants.searchbar(newQuestion, setNewQuestion, questions.length.toString(), placeholder) }
                    
                </Grid>
                <div className="flex justify-between gap-4">
                    <Button variant="secondary-outline" onClick={() => setShowQAinfo(true)}>Come scrivere una domanda efficace</Button>
                    <Button onClick={() => onClickGenerateAnswer()} disabled={generating}>
                        { generating ? "Sto cercando la risposta..." : "Invia domanda" }
                    </Button>
                </div>
            </div> }
            { infoText && 
            <Banner status="warning" className="w-full rounded-2xl text-secondary text-secondary--detail">
                La generazione della risposta sta richiedendo più tempo del previsto. 
                Ti invitiamo a tornare su questa pagina tra qualche secondo.
            </Banner> }

            <Modal visible={showQAinfo} onCancel={() => setShowQAinfo(false)} position="center" footer={false}>

                <Detail>
                    Questo servizio fornisce risposte dirette basate su informazioni specifiche tratte dai contenuti dell'opera editoriale.
                    A differenza di un chatbot conversazionale, che gestisce dialoghi interattivi e dinamici, qui puoi porre domande mirate e ricevere risposte precise e contestualizzate.
                </Detail>
                <Detail className="mt-3">
                    <strong>Suggerimento:</strong> per ottenere risultati più accurati, formula la tua domanda in modo chiaro e specifico. Ad esempio, invece di chiedere "Cognome donna estero", prova con "Come si attribuisce il cognome di una donna italiana sposata con un cittadino straniero?".
                </Detail>
                
            </Modal>

            <Accordion>
                { questions.map((question, i) => {

                    const selectedFeedback: IFeedback | false = checkExistingFeedback(question.id, givenFeedback);

                    const onClickFeedback = (e: any, value: boolean) => {
                        e.stopPropagation();
                        feedbackSetter.setJobIDFeedback(question.id); 
                        feedbackSetter.setFeedback(true);
                        feedbackSetter.setAlreadyDone(selectedFeedback);
                        feedbackSetter.setRank(selectedFeedback ? selectedFeedback.vote : value);
                    }

                    return <div key={i} onClick={() => checkFeedback(question.id, givenFeedback, updateFeedback)}>
                        <AccordionItem title={question.question}>
                            <ReactMarkdown className="text-lg">
                                {question.answer}
                            </ReactMarkdown>
                            <Row lastChild="to-right">
                                <div className={'mt-3 cursor-pointer inline-flex items-center gap-2 bg-adjust-tone-19 rounded-full px-4 py-1.5'} 
                                    onClick={e => onClickCopyAnswerText(e, question.answer)}>
                                    <div className="bg-adjust-tone-04 rounded-full inline-flex items-center justify-center w-6 h-6 text-adjust-tone">
                                        <Icon name={copy ? "status-success" : "action-copy"} size="small"></Icon>
                                    </div>
                                    <Caption>
                                        { copy ? "Testo copiato!" : "Copia testo" }
                                    </Caption>
                                </div>
                                <div className="mt-3 flex gap-2">
                                    <AIGenerated/>
                                    <FontAwesomeIcon icon={faThumbsUp} className={`cursor-pointer h-6 ${givenFeedbackIsUp(selectedFeedback)}`} onClick={e => onClickFeedback(e, true) }/>
                                    <FontAwesomeIcon icon={faThumbsDown} className={`cursor-pointer h-6 mt-1 ${givenFeedbackIsDown(selectedFeedback)}`} onClick={e => onClickFeedback(e, false) }/>
                                </div>
                            </Row>
                        </AccordionItem>
                    </div>
                })}
            </Accordion>
    </Grid>
};

export default QAs;
