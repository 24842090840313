import React, { useEffect, useState } from "react";
import { Banner, Button, Detail, H3, Modal, Textarea } from "@maggioli-design-system/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsDown, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { sendFeedback } from "../services/AIpen";
import { IFeedback } from "../Interfaces/IFeedback";

interface IFeedbackModal {
    jobID: number,
    show: boolean,
    setShow: Function,
    defaultRank: boolean,
    givenFeedback: IFeedback | false,
    updateFeedback: Function,
}

const FeedbackModal = ({ jobID, show, setShow, defaultRank, givenFeedback, updateFeedback }: IFeedbackModal): JSX.Element => {

    const [feedbackResponse, setFeedbackResponse] = useState<boolean>(false);
    const [feedbackMessage, setFeedbackMessage] = useState<string>("");
    const [rank, setRank] = useState<boolean>(defaultRank);
    const [feedbackText, setFeedbackText] = useState<string>("");

    useEffect(() => {
        if (!show) {
            setFeedbackText("");
            setFeedbackResponse(false);
            setFeedbackMessage("");
            setRank(defaultRank);
        }
    }, [show])

    useEffect(() => {
        setRank(defaultRank);
    }, [defaultRank])

    const onClickSendFeedback = async () => {
        const payload = {
            "vote": rank,
            "job_id": jobID,
            "comment": feedbackText
        }
        sendFeedback(payload).then(res => {
            if (res.status !== 200) {
                alert("Errore nell'invio del feedback");
                return;
            }
            setFeedbackResponse(res.data.status);
            setFeedbackMessage(res.data.message);
            updateFeedback({ id: 0, comment: feedbackText, vote: rank }, jobID);
        })
    }

    const onClickThumbs = (vote: boolean) => {
        if(givenFeedback) {
            return;
        }
        setRank(vote);
    }

    return <Modal visible={show} position="center" onCancel={() => setShow(false)} wide={true} footer={false}>
        <H3 className="mb-3">{givenFeedback ? "Feedback fornito" : "Fornisci feedback" }</H3>
        
        <FontAwesomeIcon icon={faThumbsUp} className={"mb-1 mr-3 h-8 cursor-pointer " + (rank && "text-brand-maggioli-07")} onClick={() => onClickThumbs(true)}/>
        <FontAwesomeIcon icon={faThumbsDown} className={"h-8 cursor-pointer " + (rank === false && "text-brand-maggioli-07")} onClick={() => onClickThumbs(false)}/>
        
        { givenFeedback ? 
            <Detail>{givenFeedback.comment}</Detail>
        : show ? <Textarea value={feedbackText} onChange={e => setFeedbackText(e.target.value)}
            placeholder="Se vuoi puoi aggiungere un breve parere sulla qualità/correttezza del contenuto generato dall'IA..."/> : <></> }
        
        { feedbackMessage !== "" && 
            <Banner status={feedbackResponse ? "success" : "warning"} className="w-full rounded-2xl text-secondary text-secondary--detail mt-3">
                {feedbackResponse ? "Feedback inviato con successo!" : feedbackMessage}
            </Banner>}
        { !givenFeedback && <Button className="mt-3" disabled={rank === null || feedbackResponse} onClick={() => onClickSendFeedback()}>Invia</Button> }
    </Modal>
};

export default FeedbackModal;
